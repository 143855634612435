<template>
	<v-app class="fsk-v-app">
		<v-app-bar class="fsk-navbar" :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<img class="fsk-navbar__logo ml-2" src="@/assets/img/fadesk-logo-white.svg" alt="Fadesk">

			<v-spacer></v-spacer>

			<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" class="mr-2 mr-lg-0">
				<v-icon>mdi-bell</v-icon>
			</v-btn>
			<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" class="mr-2 mr-lg-0">
				<v-icon>mdi-apps</v-icon>
			</v-btn>

			<v-menu bottom left :offset-y="true">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" v-bind="attrs" v-on="on">
						<v-icon>mdi-cog-outline</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item>
						<v-list-item-title>My Account</v-list-item-title>
					</v-list-item>
					<v-list-item @click="logout()">
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-navigation-drawer class="elevation-3" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app floating :expand-on-hover="$vuetify.breakpoint.lgAndUp">
			<v-list class="fsk-v-list" dense>
				<template v-for="item in items">
					<v-row v-if="item.heading" :key="`${convertToSlug(item.heading)}-nav-heading`" align="center">
						<v-col cols="6">
							<v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
						</v-col>
						<v-col cols="6" class="text-center">
							<a href="#!" class="body-2 black--text">EDIT</a>
						</v-col>
					</v-row>
					<v-list-group v-else-if="item.children" :key="`${convertToSlug(item.text)}-nav-group`" v-model="item.model">
						<template v-slot:prependIcon>
							<v-icon :class="{ 'primary--text' : isNavActive(item, 'group') }">{{ item.icon }}</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title :class="{ 'primary--text' : isNavActive(item, 'group') }">{{ item.text }}</v-list-item-title>
							</v-list-item-content>
						</template>
						<v-list-item v-for="(child) in item.children" :key="`${convertToSlug(child.text)}-nav-group`" :to="{ name: child.slug }" exact exact-active-class="v-list-item--exact-active">
							<v-list-item-action v-if="child.icon">
								<v-icon :class="{ 'primary--text' : isNavActive(child) }">{{ child.icon }}</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{ child.text }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
					<v-list-item v-else :key="`${convertToSlug(item.text)}-nav-single`" :to="{ name: item.slug }" exact exact-active-class="v-list-item--exact-active">
						<v-list-item-action>
							<v-icon :class="{ 'primary--text' : isNavActive(item) }">{{ item.icon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title :class="{ 'primary--text' : isNavActive(item) }">{{ item.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
		</v-navigation-drawer>

		<v-main>
			<div class="pa-6">
				<router-view />
			</div>
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'App',

	data: () => ({
		drawer: null,
		items: [
			{
				icon: 'mdi-home',
				text: 'Dashboard',
				slug: 'home'
			},
			{
				icon: 'mdi-account-group',
				text: 'Leads',
				slug: 'leads'
			},
			{
				icon: 'mdi-file-table-outline',
				text: 'Quotations',
				slug: 'quotations'
			},
			{
				icon: 'mdi-archive',
				'icon-alt': 'mdi-archive',
				text: 'Inventory',
				model: false,
				children: [
					{
						icon: 'mdi-menu-right',
						text: 'Products',
						slug: 'products'
					},
					{
						icon: 'mdi-menu-right',
						text: 'Services',
						slug: 'services'
					},
				],
			},
			{
				icon: 'mdi-clipboard-list-outline',
				text: 'Orders',
				slug: 'orders'
			},
			{
				icon: 'mdi-account-multiple-check',
				text: 'Customers',
				slug: 'customers'
			},
			{
				icon: 'mdi-contacts',
				text: 'Contacts',
				slug: 'contacts'
			},
			{
				icon: 'mdi-history',
				'icon-alt': 'mdi-history',
				text: 'Follow-ups',
				model: false,
				children: [
					{
						icon: 'mdi-menu-right',
						text: 'Calls',
						slug: 'calls'
					},
					{
						icon: 'mdi-menu-right',
						text: 'Meetings',
						slug: 'meetings'
					},
					{
						icon: 'mdi-menu-right',
						text: 'Emails',
						slug: 'emails'
					},
				],
			},
			{
				icon: 'mdi-checkbox-multiple-outline',
				text: 'Tasks',
				slug: 'tasks'
			},
			{
				icon: 'mdi-chart-pie',
				'icon-alt': 'mdi-chart-pie',
				text: 'Reports',
				model: false,
				children: [
					{
						icon: 'mdi-menu-right',
						text: 'Leads Converted',
						slug: 'report-leads-converted'
					},
					{
						icon: 'mdi-menu-right',
						text: 'Company Revenue',
						slug: 'report-company-revenue'
					},
					{
						icon: 'mdi-menu-right',
						text: 'Order P&L',
						slug: 'report-order-pl'
					},
				],
			},
			{
				icon: 'mdi-help-circle-outline',
				text: 'Help',
				slug: 'help'
			}
		],
	}),
	
    methods: {
        isNavActive(obj, type = null) {
			if (type == 'group') {
				return obj['children'].map(x => x['slug']).includes(this.$route.path);
			}
			else {
				return obj.slug == this.$route.name;
			}
		},
		logout() {
			this.$axios.post('/logout')
				.finally(()  => {
					// Redirect to login
					this.$router.replace('/login');
				})
		}
	}
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.v-navigation-drawer__content::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
</style>
